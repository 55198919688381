import React from "react";
import { Layout } from "../../components";

const Branding = ({ location: { pathname } }) => {
  return (
    <Layout
      seo={{
        title: "Branding",
        href: pathname,
        lang: "pl"
      }}
      langSwitch={{
        langKey: "en",
        langSlug: "/en/offer/branding/",
      }}
    >
      <section className="single_offer">
        <div className="single_offer__container">
          <div className="single_offer__header text-center">
            <h1>Branding</h1>
          </div>
          <div className="single_offer__content">
            <div class="single_offer__branding">
              <p class="text-center">
                tworzymy marki - logo - identyfikacje wizualne - opakowania i
                etykiety
              </p>
              <p>
                Tworzymy nowe marki i wprowadzamy je na rynek oraz pomagamy w
                refreshu i rebrandingu tych już istniejących. Opracowujemy
                nazwy, claimy i hasła reklamowe. Projektujemy logo i systemy
                identyfikacji wizualnej, opakowania i etykiety produktów dla
                wielu branż.
              </p>
              <h3>Dlaczego my?</h3>
              <p>
                Kilkanaście lat doświadczenia Przemyślany proces projektowy
                Bogate portfolio Klientów i projektów Doświadczenie w wielu
                branżach Kompetencje Digital i Print
              </p>
              <h3>Pełny proces realizacji projektu*</h3>
              <p>
                W zależności od potrzeb lub charakteru przedsięwzięcia
                zastosowanie mają wszystkie lub wybrane etapy projektowe. Cały
                proces realizujemy w modelu Quality Assurance (klarowna
                komunikacja, zdefiniowane wymagania, role i sposób pracy).{" "}
                <i>“Zaniedbanie przygotowania to przygotowanie porażki.”</i>
                Benjamin Franklin
              </p>
              <h3>Briefing</h3>
              <p>
                Wykonujemy wstępna analizę potrzeb, poznajemy biznes, branżę,
                ofertę Klienta oraz jego oczekiwania co do projektu i celów
                jakie są przed nim stawiane. Analizujemy strategię komunikacji
                lub opracowujemy ją od podstaw.
              </p>
              <h3>Research/benchmarking</h3>
              <p>
                Poszukujemy inspiracji i kierunków dla projektu. Przygotowujemy
                zestawienie inspirujących koncepcji kreatywnych i kierunków dla
                realizowanego projektu. Projektujemy stylescapes.
              </p>
              <h3>Copywriting</h3>
              <p>
                Projektujemy nazwy brandów, claimy, hasła reklamowe oraz content
                potrzebney do wszystkich innych materiałów . Zbieramy dane,
                analizujemy je i tworzymy wysokiej jakości treści.
              </p>
              <h3>Design. Projekty wstępne</h3>
              <p>
                Przygotowujemy wstępne projekty.Klientowi prezentujemy
                najczęściej dwie lub trzy różne propozycje spośród których
                wybierana jest jedna nad którą będziemy pracować dalej. Zawsze
                przedstawiamy rekomendacje dla projektu, który powinien być
                rozwijany.
              </p>
              <h3>Design.</h3>
              <p>
                Zespół projektowy opracowuje wszystkie materiały zgodnie z
                wybraną koncepcją kreatywną. Powstają wszystkie ustalone
                elementy, np. logo, system identyfikacji wizualnej, projekty
                etykiet, opakowań, materiały ATL/BTL. Opracowujemy Księgę Znaku
                lub BrandBook dzięki którym identyfikacja marki będzie
                uporządkowana.
              </p>
              <h3>Prezentacja</h3>
              <p>
                Po zakończeniu prac prezentujemy i omawiamy z Klientem
                przygotowane projekty. Wyjaśniamy przyświecającą im ideę,
                uzasadniamy zastosowane rozwiązania i przedstawiamy
                rekomendacje.
              </p>
              <h3>Feedback</h3>
              <p>
                Wierzymy, że Klienci podejmując z nami współpracę postanowili
                zaufać naszemu doświadczeniu i kompetencjom. Zawsze bronimy
                swoich projektów i staramy się, aby nasze rekomendacje zyskały
                akceptację. Rozumiemy jednak specyfikę niektórych przedsięwzięć
                oraz skomplikowane, zwłaszcza przy dużych projektach procesy
                decyzyjne, dlatego bardzo cenimy sobie szczery feedback, który
                bierzemy pod uwagę przy pracach projektowych.
              </p>
              <h3>Przekazanie projektów i praw</h3>
              <p>
                Po zaakceptowaniu wszystkich projektów przekazujemy pliki w
                umówionych formatach oraz prawa do ich wykorzystania w ustalonym
                zakresie.
              </p>
              <h3>Wsparcie w zakresie druku</h3>
              <p>
                Mamy wieloletnie doświadczenia oraz szerokie kontakty w branży
                drukarskiej. Doradzamy, dobieramy najlepsze rozwiązania i
                negocjujemy warunki dla naszych Klientów.
              </p>
              <h3>Opieka nad marką - umowa ramowa</h3>
              <p>
                Po zakończeniu prac proponujemy stałą opiekę projektową.
                Współpraca opiera się najczęściej o umowę ramową ze stałym
                cennikiem usług, dzięki czemu poza bardziej korzystnymi
                warunkami współpracy, dzięki znajomości brandu oraz dzięki
                pominięciu etapu każdorazowego wyceniania poszczególnych
                projektów skracamy czas ich realizacji. Uwielbiamy powoływać
                marki do życia oraz rozwijać te już istniejące - dziękujemy, że
                rozważają Państwo powierzenie nam tego ekscytującego zadania!
              </p>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};
export default Branding;
